.containerVender {
  padding-top: 30px;
  padding-bottom: 100px;

  @media (max-width: 767px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

#RFS-StepperContainer {
  #RFS-Label {
    font-weight: bold;
  }
}

#RFS-ConnectorContainer {
  left: calc(-50%) !important;
  width: 100% !important;
}

#RFS-StepButton {
  position: absolute;
  z-index: 999;
}

#RFS-Label {
  padding-top: 20px;
}

.customStepSingle {
  &.activo {
    background-color: #0c4d09 !important;
    font-weight: bold;
  }

  &.inactivo {
    background-color: #ccc !important;
    font-weight: bold;
  }

  &.completado {
    background-color: #3aaa35 !important;
    font-weight: bold;
  }
}

.labelStep {
  &.activo {
    color: #3aaa35 !important;
    font-weight: bold;
  }

  &.inactivo {
    color: #ccc !important;
    font-weight: bold;
  }

  &.completado {
    color: #3aaa35 !important;
    font-weight: bold;
  }
}

.vender_label_extra {
  font-size: 11px;
  color: #666;
}

.fs-12px {
  font-size: 12px;
}

.vender_siguiente {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  display: flex;
  align-items: center;

  background: #71dd60;

  color: #fff;
  border: none;

  border-radius: 8px;
  padding: 6px 15px;
  cursor: pointer;
  outline: inherit;

  &.disabled {
    color: #fff !important ;

    background-color: #ccc;
  }
}

.btn_verde_vender {
  background-color: #3aaa35;
  color: white;
  font-weight: bold;
  padding: 5px 25px;
  border: 0 !important;
}
.btn_verde_vender:hover,
.btn_verde_vender:focus,
.btn_verde_vender:active {
  background-color: #168011;
  color: white;
  font-weight: bold;
}

.label_vender {
  font-size: 14px;
}

.vender_text_green {
  color: #3aaa35;
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

.text_modal_instrucciones_inicio {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #666666;
}

.title_modal_vender {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: #3aaa35;
}

.subtitle_modal_vender {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;

  color: #666666;
}

.picture_upload_thumb {
  width: 240px;
  height: 137px;
  object-fit: contain;
}
.text_box_foto {
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  padding-bottom: 8px;
}

.title_modal_vender_paso_2 {
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  text-transform: uppercase;
  color: #3aaa35;
  border-bottom: 1px solid #d9d9d9;
}

.desc_moda_vender_paso_2 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130.5%;
  color: #666666;
}

.subdesc_moda_vender_paso_2 {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 130.5%;
  color: #333;
}

.slick-next::before,
.slick-prev::before {
  font-size: 40px !important;
  line-height: 1 !important;
  opacity: 1 !important;
  font-weight: bold !important;
  color: #3aaa35 !important;
  content: "<";
}

.slick-next::before {
  content: "\203A";
}

.slick-prev::before {
  content: "\2039";
}

.slick-next,
.slick-prev {
  top: 50% !important;
}

.loading_box {
  height: 137px;
}

.box_text_progress {
  p {
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

    color: #666666;
  }
}
.bar_progress {
  width: 8.3%;
  height: 2px;
  background-color: #ccc;
  padding-right: 1px;
  padding-left: 1px;
}

.invalid-input {
  border-color: #dc3545 !important;
}

.container_box_p_vender {
  position: relative;
  width: 100%;
  cursor: pointer;

  .image {
    opacity: 1;
    display: block;
    width: 240px !important;
    height: 137px !important;
    object-fit: contain;
    height: auto;
    transition: 0.5s ease;
    backface-visibility: hidden;
  }

  .middle {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .text {
    background-color: #04aa6d;
    color: white;
    font-size: 12px;
    padding: 10px;
  }
}

.container_box_p_vender:hover .image {
  opacity: 0.8;
}

.container_box_p_vender:hover .middle {
  opacity: 1;
}

.divModelo {
  @media (min-width: 767px) {
    height: 200px;
  }
}

.yearDesktop {
  @media (min-width: 767px) {
    width: 100px;
  }
}

.w-100-mobile {
  @media (max-width: 767px) {
    width: 100% !important;
  }
}

.divKm {
  min-width: 240px !important;
}

.accordionVender {
  border-radius: 0 !important;

  .card {
    border-radius: 0 !important;
    margin-top: 5px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    .card-header {
      background-color: #f3f5f3 !important;
    }

    .titleCardVender {
      padding-bottom: 15px;
      padding-top: 15px;
    }
  }
}

.StepperContainer-0-2-1 {
  @media (max-width: 767px) {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

#RFS-Connector.active,
#RFS-Connector.completed {
  border-color: #3aaa35 !important;
  border-top-width: 3px;
}
