*,
*:before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  color: $monti-black;
}

body {
  font-family: $fontFamily;
}
