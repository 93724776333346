.footerText {
  font-size: 15px;
  line-height: 22px;
  color: white;
}

.newFooter {
  h5 {
    font-size: 18px;
    font-weight: 700;
    line-height: 24.55px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #3aaa35;
  }

  ul li a,
  ul li p {
    font-size: 15px;
    font-weight: 600;
    line-height: 20.46px;
    text-align: left;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: white;
    text-align: start;
    margin-bottom: 0;
  }
}
