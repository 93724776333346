// COLORS
$monti-black: #202520;

// FONT SIZES
$fs12: 12px;
$fs14: 14px;
$fs16: 16px;
$fs18: 18px;
$fs20: 20px;
$fs24: 24px;
$fs32: 32px;
$fs40: 40px;
$fs44: 44px;
$fs48: 48px;
$fs64: 64px;

$fontFamily: "Nunito", sans-serif;
