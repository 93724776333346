.header {
  background: rgba(0, 0, 0, 1);
  //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 28px 0;

  z-index: 9999;

  @media (max-width: 768px) {
    position: inherit;
    background: rgba(0, 0, 0, 1);
  }

  .datosTop {
    color: white;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    margin-top: 20px;

    .iconos {
      color: white;
    }
  }
}

.filtroHeader {
  position: relative;
  z-index: 9999;
  bottom: 200px;
}

.bgDark {
  background: rgba(0, 0, 0, 1);
}

.carousel-caption {
  bottom: initial;
  top: 50%;
  transform: translateY(-50%);

  p {
    font-weight: bold;
    font-size: 60px;
    line-height: 75px;

    @media (max-width: 991px) {
      font-size: 45px;
      line-height: 55px;
    }

    @media (max-width: 768px) {
      font-size: 35px;
      line-height: 45px;
    }

    @media (max-width: 575px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.linkhsimple {
  color: #fff !important;
  text-decoration: none !important;
}
