.slider {
  background: #fff url("../../img/header.png") top center no-repeat;
  height: 706px;
  background-size: cover;

  h1 {
    font-family: $fontFamily;
    font-weight: 900;
    font-size: 61px;
    line-height: 95%;
    color: #ffffff;

    @media (max-width: 767px) {
      font-size: 31px;
    }
  }

  h2 {
    font-family: $fontFamily;
    font-weight: 400;
    font-size: 25px;
    line-height: 95%;
    color: #ffffff;

    @media (max-width: 767px) {
      font-size: 18px;
      margin-bottom: 100px;
    }
  }

  .formHeader {
    background-color: rgba(0, 0, 0, 0.34);
    padding: 30px;
    border-radius: 5px;
    min-height: 531px;
    position: relative;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .formHeader label {
    color: white;
    font-weight: 600;
    padding-top: 15px;
  }

  .formHeader button {
    margin-top: 20px;
    background-color: #3aaa35;
    color: white;
    font-weight: bold;
    padding: 10px;
  }

  .formHeader button:hover,
  .btnBuscar:focus,
  .btnBuscar:active {
    background-color: #168011;
    color: white;
    font-weight: bold;
  }

  .msgHeader {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
}

.sliderMain {
  max-height: 600px;

  @media (max-width: 767px) {
    max-height: inherit !important;
  }

  .carousel img {
    max-height: 600px;
    width: 100%;
    object-fit: cover;
  }
  @media (min-width: 1200px) {
    margin-bottom: -150px;
  }

  @media (max-width: 1199px) {
    margin-bottom: -120px;
  }

  @media (max-width: 991px) {
    margin-bottom: 20px;
  }

  @media (min-width: 1367px) {
    .carousel img {
      max-height: 800px;
      width: 100%;
      object-fit: cover;
    }

    margin-bottom: 120px !important;
  }
}
