.filtros {
  padding-right: 25px;
  .header {
    background-color: black;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: none;
    padding: 15px 0 15px 0;

    h3 {
      color: white;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      padding-top: 10px;
    }
  }
}

.headerFiltro {
  background-color: #000;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: none;
  padding: 15px 0;

  h3 {
    color: #fff;
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    padding-top: 10px;
  }
}

.btnBuscar {
  background-color: #3aaa35;
  color: white;
  font-weight: bold;
  padding: 10px;
}
.btnBuscar:hover,
.btnBuscar:focus,
.btnBuscar:active {
  background-color: #168011;
  color: white;
  font-weight: bold;
}
