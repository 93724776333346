.vehiculos {
  padding-bottom: 80px;

  @media (max-width: 767px) {
    padding-top: 10px;
  }

  .page-link {
    color: #666;
  }

  .page-item.active .page-link {
    background-color: #3aaa35;
    border-color: #3aaa35;
  }

  h1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    text-align: center;
    color: #202520;
    padding-bottom: 50px;
    padding-top: 60px;
  }
}

.resultadosVehiculos {
  .thumbnail {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    // object-fit: cover;
    // height: 200px;
    width: 100%;
  }
  .precio {
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    color: #202520;
  }

  .subtitulo {
    font-size: 13px;
    line-height: 16px;

    color: #4f604e;
  }
  .marca_modelo {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #202520;
    text-transform: capitalize !important;
  }
}

.ampliarVehiculo {
  padding: 50px 0 50px 0;
  h1 {
    font-family: Nunito;
    font-style: normal;
    text-transform: capitalize;
    font-size: 38px;
    padding: 20px 0 20px 0;
  }
  h2 {
    font-family: Nunito;
    font-style: normal;
    font-size: 25px;
    padding: 20px 0 20px 0;
  }
  .ficha {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 30px;

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }

    h5 {
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      margin: 0;
      color: #889c87;
    }
    h4 {
      font-family: Nunito;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 20px;
    }
  }

  .fichaDerecha {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 30px;
    flex: 1;
    display: flex;

    @media (max-width: 767px) {
      margin-top: 50px;
    }

    h1 {
      font-size: 30px;
      margin: 0;
      padding: 0;
      text-transform: capitalize;
    }

    h2 {
      font-size: 40px;
      margin: 0;
      padding: 10px 0 0 0;

      font-weight: bold;
    }

    .solicitarAsesoramiento {
      font-size: 20px;
      margin: 0;
      padding: 0;
      font-weight: bold;
    }

    .formVehiculo {
      position: relative;
      padding: 10px;
    }
    .msgHeader {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 1);
      z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      border-radius: 10px;
    }
  }
}
